$carbon-color: #333333;

.prism-message {
  margin: 16px 0px 0px 0px;

  i.icon {
    color: $carbon-color;
  }

  &__title {
    font-family: Roboto;
    font-size: 14px;
    line-height: 18px;
    color: $carbon-color;
  }

  &__text {
    &--with-title {
      font-family: Roboto;
      color: $carbon-color;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
