input,
select:not([multiple]) {
  height: 40px;
}

input,
textarea,
select,
label {
  width: 100%;
  max-width: 420px;
  display: block;
}

input {
  padding: 0.8rem;
}

select {
  -webkit-appearance: button;
  /*webkit browsers */
  -moz-appearance: button;
  /*Firefox */
  appearance: button;
}

.form-row {
  display: block;
  margin-left: 0;
  margin-right: 0;
}
