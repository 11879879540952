:root {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0 auto;
  padding: 0.5rem;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  display: flex;
  min-height: 98vh;
  flex-direction: column;
}
