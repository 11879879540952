div.crpo-container {
  max-width: 100%;
  padding: 0;
  height: 100vh;
}

div.crpo-section {
  padding: 25px 0 0 0;
  break-inside: avoid;
}

div.crpo-section:nth-child(1) {
  padding-top: 45px;
}

div.crpo-section__title {
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: white;
  background-color: #6e2262;
}

div.crpo-question-answer-item {
  flex-direction: column;
  align-items: flex-start;
  border: none;
  width: 33%;
  justify-content: flex-start;
  padding: 12px;
}

div.crpo-question {
  font-size: 14px;
}

div.crpo-answer {
  font-size: 16px;
  color: black;
  font-weight: 400;
  padding: 0;
}

div.crpo-question-answer-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
